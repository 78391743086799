import { SbBlokData, storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicListOfferingSectionStoryblok } from "@storyblok-types"

import ListSection from "@ui-library/list/ListSection"

interface ClinicListOfferingSectionProps {
  blok: BlokProps<ClinicListOfferingSectionStoryblok>["blok"]
}

export const ClinicListOfferingSection = ({ blok }: ClinicListOfferingSectionProps) => {
  return (
    <ListSection
      title={blok.heading}
      content={blok.content}
      items={blok.list_items.map(item => ({ title: item.title, content: item.description }))}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.button.map(item => (
        <StoryblokServerComponent key={item._uid} blok={item} />
      ))}
      {blok.tertiary_button?.map(item => <StoryblokServerComponent key={item._uid} blok={item} />)}
    </ListSection>
  )
}

export default ClinicListOfferingSection
